@import "../../../_scss/vars";

.wrapper {
  @include paddingVertical(7.5, 6);
  background-color: #f5f6f6;
}

.top {
  text-align: center;
  display: grid;
  justify-content: center;
}

.header {
  margin-top: 0.3rem;
  font-size: 36px;
}

.formWrapper {
  margin-top: 2rem;
}

.wewill {
  font-size: 14px;
  color: #a5a5a5;
  margin-top: 2rem;
}

.bottom {
  margin-top: 6rem;
  text-align: center;
}

.bottomInner {
  display: inline-grid;
  grid-template-columns: auto 1fr auto;
}

.bottomSide {
  //
}

.bottomMiddle {
  margin-right: 1.5rem;
}
.ballWrapper {
  display: grid;
  padding-top: 10.3rem;
  grid-gap: 2.7rem;
}
.ball {
  width: 230px;
  height: 230px;
  background-color: #f2f3f3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 2rem;
  cursor: pointer;
  &:hover,
  &:global(.active) {
    background-color: var(--bg);
    color: var(--activeColor);
    .icon {
      color: var(--activeColor);
    }
  }
}

.icon {
  display: block;
  font-size: 32px;
  color: var(--color);
}

.title {
  margin-top: 1rem;
  display: block;
  @include med;
  font-size: 18px;
  font-family: $font-cm;
  line-height: 1.3;
}
