@import "../_scss/vars";

.download {
  .left {
    position: relative;
  }

  .right {
    max-width: 590px;
  }

  .iphone {
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .iphoneInner {
    margin: 72px 32px 80px 31px;
    min-width: 193px;
    min-height: 343px;
  }

  @mixin mob() {
    .wrapper {
      display: block;
      text-align: center;
    }
    .download-copy {
      display: none;
    }
    .icons {
      display: none;
    }
    .inner-icons {
      width: auto;
    }
    .textme-wrapper {
      display: none;
    }
    .deep-wrapper {
      display: block;
    }
    .left {
      padding-left: 0;
    }
    .layout {
      background-color: #92d9ff;
      overflow: hidden;
    }
    .right {
      max-width: none;
      padding-left: 0;
      margin-top: -17.5rem;
      &:before {
        content: "";
        background-color: #fff;
        position: absolute;
        left: -999rem;
        right: -999rem;
        height: 100%;
      }
    }
    .inner {
      margin: -2.6rem auto 2rem;
      max-width: 30rem;
    }
    .inner-logo {
      background-color: #fff !important;
      border-radius: 50%;
      padding: 0.5rem;
    }
    :global {
      .global-header,
      .global-footer {
        display: none;
      }
    }
  }

  :global(body.tablet) {
    .hide-in-tablet {
      display: none;
    }
    .deep-wrapper {
      display: block;
    }
    :global {
      .primary.button {
        width: auto;
      }
      .global-footer {
        display: none;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    @include mob();
  }

  :global(body.small-mobile) {
    @include mob();
  }
}
